import React from 'react'
import {Helmet} from 'react-helmet'

import * as styles from "../../../assets/css/pages/onesearch.module.css";

export default function TopPart({content, onClickHandler}) {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" type="text/css" href="/assets/onesearch/chrome_top_part.css" />
      </Helmet>

      <div className={`parent_wrapper v1 v2 index_new1_v4 ${styles.chrome_top_part}`}>
        <div className="top_part">
          <div className="commonbox flex_commonbox">
            <div className="top_part-left">
              <ul className="install_list">
                <li className="install_points">
                  <span className="install_points-txt">Step 1:</span> Click '
                  <a
                    href={content.btn.url}
                    target="_blank"
                    onClick={onClickHandler}
                    className="analys_trk ctabtn top_continue-link color_txt">
                    CONTINUE
                  </a>
                  '
                </li>
                <li className="install_points">
                  <span className="install_points-txt">Step 2:</span> Add the extension
                </li>
                <li className="install_points point3">
                  <span className="install_points-txt install_points-txt3">Step 3: </span>
                  <span className="txt3" style={{paddingLeft: '5px'}}>Search privately: No history, no tracking & no cookies!</span>
                </li>
              </ul>
              <div className="snd_block">
                <ul className="snd_block-support">
                  <li>
                    <b>Supported OS</b>: Chrome™, Mac®, Windows®
                  </li>
                  <li>
                    <b>Language</b>: English
                  </li>
                  <li>
                    <b>License</b>: Free
                  </li>
                  <li>
                    <b>Type</b>: Private Search
                  </li>
                </ul>
              </div>
            </div>
            <div className="top_part-right">
              <span className="top_part-header">
                Safeguard your data and stay protected from retargeting. Enjoy safer, more private web browsing with the
                FREE OneSearch extension.
              </span>
              <span className="top_part-header top_part-subheader">
                No User Tracking | Pure, unfiltered results | Keyword Encryption | No Search History | No Cookies
              </span>
              <div className="snd_block">
                <div className="disc_para">Continue to the Chrome Browser Extension Store</div>
                <a
                  href={content.btn.url}
                  target="_blank"
                  onClick={onClickHandler}
                  className="analys_trk ctabtn btn_block color_cta">
                  <span className="btn_txt">CONTINUE</span>
                  <span className="btn_arrow">
                    <svg width="16" height="24" viewBox="0 0 16 24">
                      <path
                        fill="none"
                        fillRule="evenodd"
                        stroke="#FFF"
                        strokeWidth="3"
                        d="M303 60L314 49 303 38"
                        transform="translate(-301 -37)"
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom bottom_v2">
          <div className="bottom_disc">
            <p>
              Chrome is a trademark of Google LLC. Windows is a registered trademark of Microsoft Corporation. Mac is a
              registered trademark of Apple Inc. Use of them here does not imply any affiliation with or endorsement by
              their respective holders.
            </p>
            <p>
              All third-party names (including company and service names), graphics and logos used here are trademarks
              or registered trademarks of such third parties. Use of them here does not imply any affiliation with or
              endorsement by such third-parties
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
